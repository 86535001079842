import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Gauge from "../Gauge/Gauge";

export default function Home() {
  let navigate = useNavigate();

  if (localStorage.getItem("tokenmob")) {
    navigate("/dashboard");
  }

  return (
    <>
      <h2 className="text-xl font-medium">Welcome to MOBSTR</h2>
      <p>
        To get started,{" "}
        <a href="/login" className={"underline"}>
          Click Here To Log In
        </a>
      </p>
    </>
  );
}
