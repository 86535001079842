function AppSplit({appStats}) {
  return (
    <>
      
    <div className="flex justify-center py-4">
      <div className="relative flex items-center justify-center flex-grow">
        <img
          className="h-16 -mt-3"
          src="/applelogo.png"
          alt="Apple Logo"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-semibold text-sm">
          {appStats.appCounts?.ios ?? 0}
        </div>
      </div>

      <div className="relative flex items-center justify-center flex-grow">
        <img
          className="h-24 -mt-1"
          src="/androidlogo.png"
          alt="Android Logo"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-semibold text-sm">
        {appStats.appCounts?.android ?? 0}
        </div>
      </div>

      <div className="relative flex items-center justify-center flex-grow">
        <img
          className="h-16"
          src="/chromelogo.svg.webp"
          alt="Chrome Logo"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-semibold text-sm">
        {appStats.appCounts?.chrome ?? 0}
        </div>
      </div>
    </div>
    </>
  );
}

export default AppSplit;
