export default function Button(props) {
  const { type = "button", children, onClick, className = "" } = props;
  return (
    <button
      className={`text-white w-24 mt-4 bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm sm:w-auto px-5 py-2.5 text-center uppercase ${className}`}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
