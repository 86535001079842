import React, { useEffect, useState } from "react";
import AppChooser from "../AppChooser/AppChooser";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import ReactTags from "react-tag-autocomplete";
import "./tags.css";

export default function EditAppModal({
  appId,
  existingTags,
  setShowModal,
  suggestions,
  queryClient,
}) {
  const [selectedApp, setSelectedApp] = useState({});
  const [theseTags, setTheseTags] = useState([]);

  useEffect(() => {
    if (existingTags) setTheseTags(existingTags);
  }, [existingTags]);

  const onDelete = (i) => {
    const tags = theseTags.slice(0);
    tags.splice(i, 1);
    setTheseTags(tags);
  };

  const onAddition = (tag) => {
    const tags = [].concat(theseTags, tag);
    setTheseTags(tags);
  };

  // Mutations
  const mutation = useMutation(
    () => {
      let token = localStorage.getItem("tokenmob");
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/savetags`,
        {
          appId: appId,
          tags: JSON.stringify(theseTags),
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        },
      );
    },
    {
      onSuccess: (data) => {
        console.log("Saved new tags for app");
        queryClient.invalidateQueries(["myusersapps"]);
        queryClient.invalidateQueries(["myapps"]);
        queryClient.invalidateQueries(["myarchive"]);
        closeMe();
      },
    },
  );

  const closeMe = () => {
    setShowModal(false);
  };

  const clickSave = () => {
    mutation.mutate();
    setShowModal(false);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl ">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-96">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Edit Tags</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={closeMe}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 pt-0 flex-auto">
              <p className="my-4 text-slate-500 text-sm leading-relaxed">
                Add or edit app tags. Start typing to find an existing tag,
                press enter to add it.
              </p>
              <ReactTags
                tags={theseTags}
                suggestions={suggestions}
                onDelete={onDelete}
                onAddition={onAddition}
                allowNew
              />
            </div>

            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 uppercase background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={closeMe}
              >
                Close
              </button>
              <button
                className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                type="button"
                onClick={clickSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
