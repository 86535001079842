import React, { useEffect, useState } from "react";
import axios from "axios";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  CurrencyDollarIcon,
  LinkIcon,
  LocationMarkerIcon,
  PencilIcon,
  PaperClipIcon,
  CollectionIcon,
  CloudDownloadIcon,
  StarIcon,
  ShieldCheckIcon,
  BanIcon,
  CheckCircleIcon,
  XCircleIcon,
  QuestionMarkCircleIcon,
  DownloadIcon,
  XIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import Gauge from "../Gauge/Gauge";
import ThreatModal from "./ThreatModal";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import ThreatFlat from "./ThreatFlat";
import { getGrade } from "../../utils";
import html2canvas from "html2canvas";
import { Link as ScrollLink } from "react-scroll";
import { Tooltip } from "react-tooltip";

export default function AppDetail() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { bundleId } = useParams();
  const [details, setDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [threatObj, setThreatObj] = useState({});
  const [malwareCount, setMalwareCount] = useState(0);
  const [vendorCount, setVendorCount] = useState(0);
  const [isPdfView, setIsPdfView] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    loadApp();
  }, []);

  const me = useQuery(["user"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/me`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const loadApp = () => {
    let token = localStorage.getItem("tokenmob");
    axios
      .get(`${process.env.REACT_APP_API_ROOT}/app?id=${bundleId}`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        setDetails(result.data);

        let malwareCountLp = 0;
        let vendorCountLp = 0;
        Object.keys(JSON.parse(result.data.virusTotalResult)).map((vendor) => {
          console.log(vendor);
          let vendorResult = JSON.parse(result.data.virusTotalResult)[vendor]
            .result;
          if (vendorResult != null) {
            malwareCountLp++;
          }
          vendorCountLp++;
        });
        setMalwareCount(malwareCountLp);
        setVendorCount(vendorCountLp);
      });
  };

  const mutation = useMutation(
    ({ status, type }) => {
      let token = localStorage.getItem("tokenmob");
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/${type}archiveapp`,
        {
          appId: bundleId,
          status: status,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["myapps"]);
        queryClient.invalidateQueries(["myusersapps"]);
        queryClient.invalidateQueries(["myarchive"]);
        loadApp();
      },
    }
  );

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const accept = () => {
    mutation.mutate({ status: "accepted", type: "" });
    //navigate("/apps");
  };

  const reject = () => {
    mutation.mutate({ status: "rejected", type: "" });
    //navigate("/archive");
  };

  const unArchive = () => {
    mutation.mutate({ status: "", type: "un" });
    //navigate("/apps");
  };

  const showThreat = (threat) => {
    setShowModal(true);
    setThreatObj(threat);
  };

  useEffect(() => {
    if (isPdfView == true) {
      const input = document.getElementById("fullpage");
      const divHeight = input.clientHeight;
      const divWidth = input.clientWidth;
      //const ratio = divHeight / divWidth;

      html2canvas(input, {}).then((canvas) => {
        // eslint-disable-line
        // eslint-disable-line
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF("p", "mm");
        var position = 0;

        doc.addImage(
          imgData,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "MEDIUM"
        );
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(
            imgData,
            "PNG",
            0,
            position,
            imgWidth,
            imgHeight,
            "",
            "MEDIUM"
          );
          heightLeft -= pageHeight;
        }

        let dlTime = new Date();

        doc.save(
          `${
            dlTime.getFullYear().toString() +
            "_" +
            (dlTime.getMonth() + 1).toString() +
            "_" +
            dlTime.getDate().toString()
          }_${details.name}_report.pdf`
        );
        setShowLoading(false);
        setIsPdfView(false);
      });
    }
  }, [isPdfView]);

  const generatePDF = () => {
    // setShowLoading(true);
    // setIsPdfView(true);

    let token = localStorage.getItem("tokenmob");
    return axios
      .get(`${process.env.REACT_APP_API_ROOT}/apppdf?id=${bundleId}`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        responseType: "blob",
      })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = "/mobstr_logo.png";
  };

  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <>
      {details.appScanStatus?.isQueued === true && (
        <div role="alert" className="mb-5">
          <div class="bg-amber-500 text-white font-bold rounded-t-lg px-4 py-2">
            Warning
          </div>
          <div class="border border-t-0 border-amber-400 rounded-b-lg bg-red-100 px-4 py-3 text-amber-700">
            <p>
              This scan is still in progress and still pending. You can 'Peek'
              at the results as they load, but the final score wont be available
              until the scan has finished.
            </p>
          </div>
        </div>
      )}
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="w-full md:w-1/3">
          <div className="flex items-center justify-start md:justify-start">
            <div className="w-1/4">
              <img
                src={`${details.icon}`}
                className="rounded-2xl align-middle border-none max-w-full h-auto"
                onError={addDefaultSrc}
                alt="App Icon"
              />
            </div>
            <div className="w-3/4 pl-3">
              <h2 className="text-xl md:text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                {details.name}
              </h2>
              <h5 className="text-md md:text-lg leading-7 text-gray-900 sm:text-lg sm:truncate">
                {details.developer}
              </h5>
              <h5 className="text-md md:text-lg leading-7 text-gray-900 sm:text-lg sm:truncate">
                {details.version}
              </h5>
            </div>
          </div>
        </div>

        <div className="w-full md:w-2/3 flex flex-wrap space-y-2 mt-4 md:mt-0">
          <div className="w-full flex justify-end text-right space-x-2">
            {!details.appScanStatus?.isQueued && (
              <span className="mb-2">
                <button
                  onClick={generatePDF}
                  type="button"
                  className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                >
                  <DownloadIcon
                    className="-ml-1 mr-2 h-5 w-5 inline"
                    aria-hidden="true"
                  />
                  Download PDF
                </button>
              </span>
            )}

            <span className="mb-2">
              <a href={details.url} target="_blank" rel="noopener noreferrer">
                <button
                  type="button"
                  className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                >
                  <LinkIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-500 inline"
                    aria-hidden="true"
                  />
                  View In Store
                </button>
              </a>
            </span>
          </div>

          <div className="w-full flex justify-end text-right space-x-2">
            {!details.appScanStatus?.isQueued && (
              <>
                {me.data && me.data.data.approver && (
                  <>
                    {!details.isArchived && (
                      <span className="mb-2">
                        <button
                          type="button"
                          className="text-white bg-[#ff3c00] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                          onClick={reject}
                        >
                          <XIcon
                            className="-ml-1 mr-2 h-5 w-9 inline"
                            aria-hidden="true"
                          />
                          Reject & Archive
                        </button>
                      </span>
                    )}
                    {!details.isArchived && details.status !== "accepted" && (
                      <span className="mb-2">
                        <button
                          onClick={accept}
                          type="button"
                          className="text-white bg-[#18a10c] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                        >
                          <CheckIcon
                            className="-ml-1 mr-2 h-5 w-5 inline"
                            aria-hidden="true"
                          />
                          Approve
                        </button>
                      </span>
                    )}
                    {details.isArchived && (
                      <span className="mb-2">
                        <button
                          onClick={unArchive}
                          type="button"
                          className="text-white bg-[#18a10c] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                        >
                          <CheckIcon
                            className="-ml-1 mr-2 h-5 w-5 inline"
                            aria-hidden="true"
                          />
                          UN-Archive
                        </button>
                      </span>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div id="fullpage">
        <div className="bg-white overflow-hidden sm:rounded-lg mt-6 border-solid border-gray-200 border ">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex mb-8">
              <div className="w-10/12">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-3">
                  {isPdfView ? details.name : "Application Information"}
                </h3>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CollectionIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {details.genre}
                  </div>
                  <div
                    className="mt-2 flex items-center text-sm text-gray-500"
                    title={"The country store that the app was downloaded from"}
                  >
                    <LocationMarkerIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    US
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CurrencyDollarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {details.price == 0 ? "FREE" : details.price}
                  </div>
                  <div
                    className="mt-2 flex items-center text-sm text-gray-500"
                    title={"The date that this scan was performed"}
                  >
                    <CalendarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {new Date(details.updatedAt).toLocaleDateString("en-GB")}
                  </div>
                  <div
                    className="mt-2 flex items-center text-sm text-gray-500"
                    title={"The number of downloads from the store"}
                  >
                    <CloudDownloadIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {details.installs}
                  </div>
                  <div
                    className="mt-2 flex items-center text-sm text-gray-500"
                    title={"The store rating"}
                  >
                    <StarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {details.rating}
                  </div>
                </div>
              </div>
              <div className="w-2/12 flex justify-end items-center h-auto">
                <div className="flex items-center flex-col relative">
                  {details.appScanStatus?.isQueued ? (
                    <div style={{ width: 130 }}>
                      <Gauge value={-1} />
                    </div>
                  ) : (
                    <div style={{ width: 130 }}>
                      <Gauge value={details.score} />
                    </div>
                  )}
                  <div className="absolute top-0 right-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-gray-500 cursor-pointer"
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    />
                    {showTooltip && (
                      <div
                        className="absolute top-0 right-full mt-1 p-2 bg-gray-100 rounded-md shadow-md text-sm"
                        style={{ minWidth: "500px" }}
                      >
                        <p className="text-sm text-gray-600">
                          <ul>
                            <li>
                              <strong>A*</strong> - The app is very low risk
                            </li>
                            <li>
                              <strong>A</strong> - The app is low risk with few
                              issues
                            </li>
                            <li>
                              <strong>B</strong> - The app contains some risk
                              and should be reviewed
                            </li>
                            <li>
                              <strong>C</strong> - The app is medium risk, the
                              report should be carefully reviewed, and
                              mitigation made where possible.
                            </li>
                            <li>
                              <strong>D</strong> - The app is high risk, Usage
                              of the app should be considered for situations
                              with high risk data or users. The report should be
                              carefully reviewed and mitigation made where
                              possible.
                            </li>
                            <li>
                              <strong>F</strong> - The app is high risk and
                              contains significant security and privacy concerns
                            </li>
                            <li>
                              <strong>Pending</strong> - The scan is still in
                              progress.
                            </li>
                          </ul>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Description
                  </dt>
                  <dd
                    className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                    dangerouslySetInnerHTML={{
                      __html: details.description?.slice(0, 500),
                    }}
                  ></dd>
                </div>
                {details.privacy && (
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Privacy Policy
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <a href={details.privacy} target="_blank">
                        {details.privacy}
                      </a>
                    </dd>
                  </div>
                )}
              </dl>
            </div>
          </div>
        </div>

        <div className="bg-white border-solid border-gray-200 border overflow-hidden sm:rounded-lg mt-6">
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid grid-cols-4 sm:gap-4 sm:px-6">
                <div className="flex justify-center sm:justify-start mb-4 sm:mb-0">
                  <div className="text-sm text-gray-500 font-bold">
                    <div style={{ width: 170 }}>
                      <ScrollLink
                        to="permissions"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-50}
                        className="cursor-pointer"
                      >
                        <Gauge
                          value={details.scorePermissions}
                          label="Permissions"
                          showVal={false}
                        />
                      </ScrollLink>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center sm:justify-start mb-4 sm:mb-0">
                  <div className="text-sm text-gray-500 font-bold">
                    <div style={{ width: 170 }}>
                      <ScrollLink
                        to="dependencies"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-50}
                        className="cursor-pointer"
                      >
                        <Gauge
                          value={details.scoreDepenency}
                          label="Dependencies"
                          showVal={false}
                        />
                      </ScrollLink>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center sm:justify-start mb-4 sm:mb-0">
                  <div className="text-sm text-gray-500 font-bold">
                    <div style={{ width: 170 }}>
                      <ScrollLink
                        to="privacy"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-50}
                        className="cursor-pointer"
                      >
                        <Gauge
                          value={details.scorePrivacy}
                          label="Privacy"
                          showVal={false}
                        />
                      </ScrollLink>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center sm:justify-start">
                  <div className="text-sm text-gray-500 font-bold">
                    <div style={{ width: 170 }}>
                      <ScrollLink
                        to="security"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-50}
                        className="cursor-pointer"
                      >
                        <Gauge
                          value={details.scoreSecurity}
                          label="Security"
                          showVal={false}
                        />
                      </ScrollLink>
                    </div>
                  </div>
                </div>
              </div>
            </dl>
          </div>
        </div>

        {details.binType != "crx" && (
          <div
            className="bg-white border-solid border-gray-200 border overflow-hidden sm:rounded-lg mt-6"
            id="security"
          >
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Basic Security Checks
              </h3>
              <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"></div>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid grid-cols-4 sm:gap-4 sm:px-6">
                  <dt className="text-sm text-gray-500 font-bold">
                    Dev Platform
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 ">
                    {details.devPlatform}
                  </dd>
                  <dt className="text-sm font-bold text-gray-500 pt-4 sm:pt-0">
                    In-App Purchasing
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 ">
                    {details.iapMax > 0 ? (
                      <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600" />
                    ) : (
                      <XIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" />
                    )}
                  </dd>
                </div>

                {details.binType == "apk" && (
                  <>
                    <div className="bg-white px-4 py-5 sm:grid grid-cols-4 sm:gap-4 sm:px-6">
                      <dt className="text-sm text-gray-500 font-bold">
                        Anti Emulation
                        <sup
                          title={
                            "Anti emulation prevents an application from being run from an emulator, to prevent an attacker potentially using a manipulated Operating System"
                          }
                        >
                          <QuestionMarkCircleIcon
                            className={"w-4 inline ml-1 pb-1"}
                          />
                        </sup>
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 ">
                        {details.threats &&
                        details.threats.findIndex((a) => a.threatId == 13) >
                          -1 ? (
                          <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600" />
                        ) : (
                          <XIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" />
                        )}
                      </dd>
                      <dt className="text-sm font-bold text-gray-500 pt-4 sm:pt-0">
                        Anti Debug
                        <sup
                          title={
                            "Debug protection prevents an attacker from finding out extra details about an application by seeing its logs and halting and manipulating an apps execution"
                          }
                        >
                          <QuestionMarkCircleIcon
                            className={"w-4 inline ml-1 pb-1"}
                          />
                        </sup>
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 ">
                        {details.threats &&
                        details.threats.findIndex((a) => a.threatId == 5) >
                          -1 ? (
                          <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600" />
                        ) : (
                          <XIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" />
                        )}
                      </dd>
                    </div>

                    <div className="bg-gray-50 px-4 py-5 sm:grid grid-cols-4 sm:gap-4 sm:px-6">
                      <dt className="text-sm text-gray-500 font-bold">
                        TapJacking Protection
                        <sup
                          title={
                            "TapJacking is an attack where the attacker hijacks the users taps and tricks them into doing something they did not intend, usually by overlaying an interface over the top of an existing one. Apps can prevent other apps from doing this."
                          }
                        >
                          <QuestionMarkCircleIcon
                            className={"w-4 inline ml-1 pb-1"}
                          />
                        </sup>
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 ">
                        {details.threats &&
                        details.threats.findIndex((a) => a.threatId == 12) >
                          -1 ? (
                          <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600" />
                        ) : (
                          <XIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" />
                        )}
                      </dd>
                      <dt className="text-sm font-bold text-gray-500 pt-4 sm:pt-0">
                        Screenshot Protection
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 ">
                        {details.threats &&
                        details.threats.findIndex((a) => a.threatId == 7) >
                          -1 ? (
                          <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600" />
                        ) : (
                          <XIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" />
                        )}
                      </dd>
                    </div>
                  </>
                )}
              </dl>
            </div>
          </div>
        )}

        {details.iapMin && (
          <div className="bg-white border-solid border-gray-200 border overflow-hidden sm:rounded-lg mt-6">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                In App Purchasing
              </h3>
              <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"></div>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white px-4 py-5 sm:grid grid-cols-4 sm:gap-4 sm:px-6">
                  <dt className="text-sm text-gray-500 col-span-2">
                    <b>Amount</b>
                  </dt>
                  <dd
                    className={`mt-1 text-sm sm:mt-0 ${
                      details.iapMax > 10000 ? "text-red-600" : "text-gray-900"
                    }`}
                  >
                    {details.iapMin === details.iapMax
                      ? `£${(details.iapMin / 100).toFixed(2)}`
                      : `£${(details.iapMin / 100).toFixed(2)} to £${(
                          details.iapMax / 100
                        ).toFixed(2)}`}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        )}

        {details.privacy && (
          <div
            className="bg-white border-solid border-gray-200 border overflow-hidden sm:rounded-lg mt-6"
            id="privacy"
          >
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Privacy
              </h3>
              <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"></div>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white px-4 py-5 sm:grid grid-cols-4 sm:gap-4 sm:px-6">
                  <dt className="text-sm text-gray-500 col-span-2">
                    <b>Privacy Policy</b>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 ">
                    {details.privacy ? (
                      <>
                        <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600 inline-block" />
                        Application has a privacy policy -{" "}
                        <a href={details.url} target="_blank">
                          View Policy
                        </a>
                      </>
                    ) : (
                      <>
                        <XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" />
                        Application DOES NOT have a privacy policy
                      </>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        )}

        <div
          className="bg-white border-solid border-gray-200 border overflow-hidden sm:rounded-lg mt-6"
          id="permissions"
        >
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Permissions
            </h3>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"></div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid grid-cols-5 sm:gap-5 sm:px-6">
                <dt className="text-sm text-gray-500 font-bold w-1/5">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0  ">
                  Purpose
                </dd>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-2">
                  Description
                </dd>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 ">
                  Severity
                </dd>
              </div>

              {details.permissions &&
                details.permissions.map((perm) => {
                  return (
                    <div
                      className={
                        (perm.severity == "dangerous"
                          ? "bg-rose-200"
                          : "bg-white") +
                        " px-4 py-5 sm:grid grid-cols-5 sm:gap-4 sm:px-6"
                      }
                    >
                      <dt className="text-xs text-gray-500 font-bold flex-wrap">
                        {perm.name}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 capitalize sm:mt-0  ">
                        {perm.purpose}
                      </dd>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-2">
                        {perm.description}
                      </dd>
                      <dd
                        className={
                          (perm.severity == "dangerous"
                            ? "text-red-600"
                            : "text-gray-900") +
                          " mt-1 text-sm capitalize sm:mt-0"
                        }
                      >
                        {perm.severity}
                      </dd>
                    </div>
                  );
                })}
            </dl>
          </div>
        </div>

        {details.binType != "crx" && (
          <div
            className="bg-white border-solid border-gray-200 border overflow-hidden sm:rounded-lg mt-6"
            id="dependencies"
          >
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Dependency Analysis
              </h3>
              <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                The following SDK's / Libraries were found in the appliation.
                Dependencies can become out of date, or introduce external
                vulnerabilities into applications. All dependencies should be
                reviewed, and recently vulnerable instances will be highlighted
                here.
              </div>
            </div>
            {details.sdks && details.sdks.length > 0 ? (
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid grid-cols-5 sm:gap-4 sm:px-6">
                    <dt className="text-sm text-gray-500 font-bold col-span-2">
                      Name
                    </dt>
                    <dt className="text-sm text-gray-500 font-bold">
                      Category
                    </dt>
                    <dd className="text-sm text-gray-500 font-bold">Risk</dd>
                  </div>

                  {details.sdks.map((sdk) => {
                    return (
                      <div
                        className={classNames(
                          "px-4 py-5 sm:grid grid-cols-5 sm:gap-4 sm:px-6",
                          sdk.sdk.score > 0 ? "bg-red-600" : "bg-white"
                        )}
                      >
                        <dt className={classNames(
                          "text-sm  col-span-2",
                          sdk.sdk.score > 0 ? "text-white font-bold" : "text-gray-500"
                        )}>
                          <img
                            src={
                              sdk.sdk.description
                                ? sdk.sdk.description
                                : "https://img.freepik.com/premium-vector/sdk-software-development-kit-icon-vector-stock-illustration_100456-11693.jpg?w=200"
                            }
                            className={"w-14 inline-block mr-3"}
                          />
                          <b>
                            {sdk.sdk.name == "Unknown"
                              ? "Unknown (" + sdk.sdk.id + ")"
                              : sdk.sdk.name}
                          </b>{" "}
                          {sdk.sdk.name != "Unknown" && (
                            <a
                              href={sdk.sdk.web}
                              className="text-xs"
                              target="_blank"
                            >
                              (details)
                            </a>
                          )}
                        </dt>
                        <dt className={classNames(
                          "text-sm",
                          sdk.sdk.score > 0 ? "text-white" : "text-gray-500"
                        )}>
                          {sdk.sdk.category}
                        </dt>
                        <dd className={classNames(
                          "mt-1 text-sm  sm:mt-0",
                          sdk.sdk.score > 0 ? "text-white" : "text-gray-900"
                        )}
                       >
                          {sdk.sdk.score > 0 &&  

<span className="inline-flex items-center bg-red-100 text-red-600 text-sm font-bold font-medium px-1 py-0.5 rounded ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="red"
                                  className="w-5 h-5 inline-block mr-1"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v4m0 4h.01M21.17 18.5L13.5 3.5a1.5 1.5 0 00-3 0L2.83 18.5a1.5 1.5 0 001.29 2.25h15.76a1.5 1.5 0 001.29-2.25z"
                                  />
                                </svg>
                                ALERT
                              </span>}
                          
                          {sdk.sdk.score > 0 ? <p>{sdk.sdk.note}</p> : "Normal"}
                        </dd>
                      </div>
                    );
                  })}
                </dl>
              </div>
            ) : (
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 ">
                    There's no SDK information detected for this app.
                  </div>
                </dl>
              </div>
            )}
          </div>
        )}

        <div className="bg-white border-solid border-gray-200 border overflow-hidden sm:rounded-lg mt-6">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Malware Analysis
            </h3>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              The application binary has been scanned against {vendorCount}{" "}
              security vendors. Investigate any that are listed below.
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white px-4 py-5 grid grid-cols-4 sm:gap-4 sm:px-6">
                {details.virusTotalResult &&
                  Object.keys(JSON.parse(details.virusTotalResult)).map(
                    (vtr) => {
                      const result = JSON.parse(details.virusTotalResult);
                      return (
                        result[vtr].result != null && (
                          <div className="text-sm text-gray-500">
                            <b>{vtr}</b>
                            <p className="mt-1 text-xs">
                              <span className="inline-flex items-center bg-red-100 text-red-600 text-sm font-bold font-medium px-1 py-0.5 rounded">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="red"
                                  className="w-5 h-5 inline-block mr-1"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v4m0 4h.01M21.17 18.5L13.5 3.5a1.5 1.5 0 00-3 0L2.83 18.5a1.5 1.5 0 001.29 2.25h15.76a1.5 1.5 0 001.29-2.25z"
                                  />
                                </svg>
                                SUSPICIOUS
                              </span>
                            </p>
                          </div>
                        )
                      );
                    }
                  )}
                {malwareCount == 0 && <p>No Suspicious Reports Identified</p>}
              </div>
            </dl>
          </div>
        </div>

        <div className="bg-white border-solid border-gray-200 border overflow-hidden sm:rounded-lg mt-6">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Static Analysis
            </h3>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              The below table lists the areas of risk found within the
              application, along with the locations in code they were found.
              These should be reviewed and mitigated where neccesary. Items in{" "}
              <p style={{ color: "green", display: "contents" }}>green</p> below
              are included for completeness but have deemed to be low risk.
            </div>
          </div>
          {details.threats && details.threats.length > 0 ? (
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm text-gray-500 font-bold">Item</dt>

                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 ">
                    Severity
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 "> </dd>
                </div>

                {details.threats &&
                  details.threats.map((threat) => {
                    return (
                      <>
                        <div
                          className={classNames(
                            "px-4 py-5 sm:grid grid-cols-3 sm:gap-4 sm:px-6",
                            threat.threat.riskScore > 5
                              ? "bg-red-600"
                              : "bg-white"
                          )}
                        >
                          <dt className={classNames(
                            "text-sm",
                            threat.threat.riskScore > 5
                              ? "text-white"
                              : "text-grey-900"
                          )}>
                            <span className="font-bold flex-wrap">
                              {threat.threat.name}
                            </span>
                            <br />
                            {threat.threat.description}
                          </dt>
                          <dd
                            className={
                              (threat.threat.riskScore > 5
                                ? "text-white"
                                : threat.threat.riskScore < 0
                                ? "text-green-600"
                                : "text-gray-900") +
                              " mt-1 text-sm text-gray-900 sm:mt-0"
                            }
                          >
                            {threat.threat.riskScore > 5
                              ? 
                              <span className="inline-flex items-center bg-red-100 text-red-600 text-sm font-bold font-medium px-1 py-0.5 rounded">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="red"
                                  className="w-5 h-5 inline-block mr-1"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v4m0 4h.01M21.17 18.5L13.5 3.5a1.5 1.5 0 00-3 0L2.83 18.5a1.5 1.5 0 001.29 2.25h15.76a1.5 1.5 0 001.29-2.25z"
                                  />
                                </svg>
                                HIGH RISK
                              </span>
                              : threat.threat.riskScore < 0
                              ? "No Risk : Positive Security Action"
                              : "Medium"}
                          </dd>
                          <dd className="mt-1 text-xs text-gray-900 sm:mt-0 text-right">
                            {!isPdfView && (
                              <button
                                className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                                type="button"
                                onClick={() => showThreat(threat)}
                              >
                                More Details
                              </button>
                            )}
                          </dd>
                        </div>
                        {isPdfView && (
                          <div className="bg-white px-4 py-5 sm:grid grid-cols-4 sm:gap-4 sm:px-6">
                            <dd className="mt-1 text-xs text-gray-900 sm:mt-0 col-span-4 ">
                              <ThreatFlat threatObj={threat} />
                            </dd>
                          </div>
                        )}
                      </>
                    );
                  })}
              </dl>
            </div>
          ) : (
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 ">
                  There are no static issues currently detected for this app.
                </div>
              </dl>
            </div>
          )}
        </div>
      </div>

      <ThreatModal
        showModal={showModal}
        setShowModal={setShowModal}
        threatObj={threatObj}
      />

      {showLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">
            Creating Pdf...
          </h2>
          <p className="w-1/3 text-center text-white"></p>
        </div>
      )}
    </>
  );
}
