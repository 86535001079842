import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Gauge from "../Gauge/Gauge";

export default function FAQ() {
  let navigate = useNavigate();

  return (
    <>
      <section className="dark:bg-gray-800 dark:text-gray-100">
        <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
          <h2 className="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">
            Frequently Asked Questions
          </h2>
          <div className="divide-y divide-gray-700">
            <div className="py-6 space-y-2 md:grid md:grid-cols-12 md:gap-8 md:space-y-0">
              <h3 className="font-semibold md:col-span-5">
                What types of applications can I add?
              </h3>
              <p className="md:pl-0 md:col-span-7">
                You can add Free Android (US Store), iOS (US Store) and Chrome
                Applications/Extensions.
              </p>
            </div>
            <div className="py-6 space-y-2 md:grid md:grid-cols-12 md:gap-8 md:space-y-0">
              <h3 className="font-semibold md:col-span-5">
                What about paid apps?
              </h3>
              <p className="md:pl-0 md:col-span-7">
                We are unable to add paid apps automatically, You could either
                source the application binary and upload it manually if you
                already own it, or contact us to discuss further.{" "}
              </p>
            </div>
            <div className="py-6 space-y-2 md:grid md:grid-cols-12 md:gap-8 md:space-y-0">
              <h3 className="font-semibold md:col-span-5">
                Do you offer any MDM services?
              </h3>
              <p className="md:pl-0 md:col-span-7">
                No, MOBSTR simply calculates the risk scores of applications
                allowing you to make whatever decisions, and take whatever
                actions you wish.
              </p>
            </div>
            <div className="py-6 space-y-2 md:grid md:grid-cols-12 md:gap-8 md:space-y-0">
              <h3 className="font-semibold md:col-span-5">
                Do I have to install anything to use MOBSTR?
              </h3>
              <p className="md:pl-0 md:col-span-7">
                No, MOBSTR is entirely web based, and apps are obtained from the
                relevant app stores to be scanned.
              </p>
            </div>
            <div className="py-6 space-y-2 md:grid md:grid-cols-12 md:gap-8 md:space-y-0">
              <h3 className="font-semibold md:col-span-5">
                How often are apps re-scanned?
              </h3>
              <p className="md:pl-0 md:col-span-7">
                When an app is updated in the app store by the publisher, our
                system detects a new version is available, and will download and
                re-scan it, you will get a notification incudling any changes to
                the risk score, via email.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
