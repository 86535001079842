import React from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import axios from "axios";

export default function Billing() {
  const myusers = useQuery(["myusers"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/myusers`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const myapps = useQuery(["myapps"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/myapps`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const myarchive = useQuery(["myarchive"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/myarchive`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const me = useQuery(["user"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/me`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  return (
    <>
      <div className="flex justify-between">
        <h2 className="text-xl font-medium">Billing</h2>
      </div>
      <div className="bg-white py-8 px-4 mt-6 shadow sm:rounded-lg sm:px-10">
        <div className="border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Subscription
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="inline-block border border-green-600 rounded py-1 px-3 text-white bg-green-600">
                 Active
                </span>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Username</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {me.data?.data.email}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Licence Number</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              MW24-{me.data?.data.id*4000}-{me.data?.data?.parentId ? me.data?.data?.parentId*4000 : 'M'}-W1C1M{me.data?.data?.hasMDM ? '1' : '0'}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Subscription End Date</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {me.data?.data.trialExpiry ? new Date(me.data?.data.trialExpiry).toLocaleDateString() : 'Unlimited'}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Apps</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {myapps.data?.data.length} / {me.data?.data.appLimit}
                <button
              type="button"
              onClick={() => window.location = 'mailto:support@mobstr.io?subject=Increase App Limit'}
              className="ml-10 text-white bg-green-600 hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            >
              Get More Apps!
            </button>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Users</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {myusers.data?.data.length} / &infin;
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
