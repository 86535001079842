import React, { useState } from "react";
import AppChooser from "../AppChooser/AppChooser";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";

export default function ThreatFlat({ threatObj }) {
  return (
    <div className=" p-2 flex-auto">
      <h2 className={"font-bold mt-3 mb-3"}>Details</h2>
      <p className={" mt-3 mb-3"}>
        The details below offer the line file and line number of the detected
        issue
      </p>
      <div class=" mb-10 border-2 rounded p-2">
        {threatObj.threatId == 2 &&
          JSON.parse(threatObj.details).map((tdd) => {
            if (
              tdd.File.split("/")[tdd.File.split("/").length - 1].indexOf(
                "BNDLTOOL.SF",
              ) > -1 ||
              tdd.File.split("/")[tdd.File.split("/").length - 1].indexOf(
                "MANIFEST.MF",
              ) > -1
            ) {
              return (
                <>
                  <p style={{ color: "green" }}>
                    {tdd.File.split("/")[tdd.File.split("/").length - 1]}:
                    {tdd.StartLine}
                  </p>
                  <div
                    className={
                      "text-xs border-2 border-gray-300 m-2 p-2 bg-gray-100"
                    }
                  >
                    {tdd.Match}
                  </div>
                </>
              );
            } else {
              return (
                <>
                  <p style={{ fontWeight: "bold" }}>
                    {tdd.File.split("/")[tdd.File.split("/").length - 1]}:
                    {tdd.StartLine}
                  </p>
                  <div
                    className={
                      "text-xs border-2 border-gray-300 m-2 p-2 bg-gray-100"
                    }
                  >
                    {tdd.Match}
                  </div>
                </>
              );
            }
          })}

        {threatObj.threatId != 2 && (
          <>
            {JSON.parse(threatObj.details).map((tdd) => {
              return (
                <>
                  <p>
                    {tdd.file.replace("resources/", "").replace("sources", "")}{" "}
                    : {tdd.line}
                  </p>
                  <div
                    className={
                      "text-xs border-2 border-gray-300 m-2 p-2 bg-gray-100"
                    }
                  >
                    {tdd.sample}
                  </div>
                </>
              );
            })}
          </>
        )}
      </div>
      <h2 className={"font-bold mt-13 mb-3"}>Explanation</h2>

      <div class="border-2 rounded p-2 mb-3">
        {threatObj.threat.explanation}
      </div>

      <h2 className={"font-bold mt-13 mb-3"}>Impact</h2>

      <div class="border-2 rounded p-2 mb-3">{threatObj.threat.impact}</div>

      <h2 className={"font-bold mt-13 mb-3"}>How to Review</h2>

      <div class="border-2 rounded p-2">{threatObj.threat.howtoreview}</div>
    </div>
  );
}
