import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Gauge from "../Gauge/Gauge";
import StarRating from "../StarRating/StarRating";
import { Animated } from "react-animated-css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faApple,
  faAndroid,
  faChrome,
} from "@fortawesome/free-brands-svg-icons";
import { UserCircleIcon } from "@heroicons/react/outline";
// import Avatar from "../Avatar/Avatar";
import EditAppModal from "../AppIndex/EditAppModal";

export default function Home({ queryClient }) {
  const [showAddAppModal, setShowAddAppModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [userApps, setUserApps] = useState();
  const [showEditAppModal, setShowEditAppModal] = useState(false);
  const [editTags, setEditTags] = useState([]);
  const [editAppId, setEditAppId] = useState();
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [theseTags, setTheseTags] = useState([]);

  let navigate = useNavigate();

  const myusersapps = useQuery(["myusersapps"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/myusersapps`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const me = useQuery(["user"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/me`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  useEffect(() => {
    if (myusersapps && myusersapps.data && myusersapps.data.data) {
      if (searchTerm.length > 0) {
        setUserApps(
          myusersapps.data.data.filter(
            (ta) =>
              ta.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1,
          ),
        );
      } else {
        setUserApps(myusersapps.data.data);
      }
    }
  }, [searchTerm, JSON.stringify(myusersapps.data)]);

  const addDefaultSrc = (ev) => {
    ev.target.src = "/mobstr_logo.png";
  };

  return (
    <>
      <div className="flex justify-between">
        <h2 className="text-xl font-medium">My Users Apps</h2>
      </div>

      <div className="bg-white py-8 px-4 mt-6 shadow sm:rounded-lg sm:px-10">
        <div className="w-full flex flex-row">
          <input
            type="text"
            className="px-3 py-1 border border-gray-300 rounded-md focus:ring-mobstr-purple focus:border-mobstr-purple h-11 col-span-1"
            placeholder="Search Apps"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="w-full flex flex-row my-5">
          <ul role="list" className="divide-y divide-gray-200 w-full">
            {myusersapps.status == "success" &&
              userApps &&
              userApps.map((app, index) => (
                <Animated animationIn="fadeInUp" animationInDelay={index * 100}>
                  <li
                    key={index}
                    className={"py-6"} // className={status == 'rejected' ? 'flex py-6 bg-red-100' : 'flex py-6 bg-green-100'}
                  >
                    <div className="flex flex-1 justify-between">
                      <span className="relative">
                        <img
                          src={`${app.icon}`}
                          className="rounded-2xl h-16 w-16 sm:h-28 sm:w-28 align-middle border-none cursor-pointer object-contain"
                          onError={addDefaultSrc}
                          onClick={() => navigate(`/app/${app.appId}`)}
                        />
                        <span className="absolute top-0 left-0 h-7 w-7 flex items-center justify-center align-middle text-center bg-mobstr-purple text-white rounded-full">
                          {app.platform == "ios" && (
                            <FontAwesomeIcon icon={faApple} />
                          )}
                          {app.platform == "android" && (
                            <FontAwesomeIcon icon={faAndroid} />
                          )}
                          {app.platform == "chrome" && (
                            <FontAwesomeIcon icon={faChrome} />
                          )}
                        </span>
                      </span>

                      <div className="ml-4 flex flex-1">
                        <div className="text-base font-medium text-gray-900">
                          <h3
                            className={
                              app.status == "rejected"
                                ? "text-red-700"
                                : app.status == "accepted"
                                  ? "text-green-700"
                                  : ""
                            }
                            onClick={() => navigate(`/app/${app.appId}`)}
                          >
                            <span className="cursor-pointer mr-2">
                              {app.name}
                            </span>{" "}
                            <StarRating rating={app.rating}></StarRating>
                          </h3>
                          {/* <p className={"ml-4 text-lg " + ((app.score<95&&app.score>=90) ? 'text-orange-500': (app.score<90)? 'text-red-700': 'text-green-700')}>Security Score: <b>{app.score}</b></p> */}
                          {app.developer && (
                            <p className="text-sm text-gray-500">
                              {app.developer}
                            </p>
                          )}
                          {/* <p className="ml-4"><b>{app.price}</b> </p> */}
                          {app.genre && (
                            <p className="mt-1 text-xs text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 inline-block mr-1"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                                />
                              </svg>
                              {app.genre}
                            </p>
                          )}
                          {app.installs && (
                            <p className="mt-1 text-xs text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 inline-block mr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                              </svg>
                              {app.installs && `${app.installs}`}
                            </p>
                          )}
                          {app.url && (
                            <p className="mt-1 text-xs text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5 inline-block mr-1"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                />
                              </svg>
                              <a href={app.url} target="_blank">
                                View in App Store
                              </a>
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-center">
                      {app.appScanStatus.isQueued ? (
                        <div className="w-24 md:w-36">
                          <Gauge value={-1} />
                        </div>
                      ) : (
                        <div className="w-24 md:w-36">
                          <Gauge value={app.score} />
                        </div>
                      )}
                      </div>
                    </div>
                    <div className="mt-2 flex flex-1 flex-row">
                      {app.tags &&
                        app.tags.map((taga) => {
                          return (
                            <span
                              className={
                                "text-[9px] inline-flex items-center font-bold leading-sm uppercase px-3 py-1 mr-1 bg-mobstr-purple text-white rounded-full"
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-4 h-4 mr-1"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 6h.008v.008H6V6z"
                                />
                              </svg>

                              {taga.name}
                            </span>
                          );
                        })}
                      {me && me.data && me.data.data?.parentId == null && (
                        <div
                          className="text-xs text-gray-300 flex items-center justify-center cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditAppId(app.appId);
                            setEditTags(app.tags);
                            setShowEditAppModal(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                          <p>Edit Tags</p>
                        </div>
                      )}
                    </div>
                    {/* <div className="mt-2 flex flex-1 flex-row">
                        {app.users.map(usr =>
                        <div title={usr}>
                          <UserCircleIcon className={"w-8 text-gray-500"} />
                          </div>
                        )}
                      </div> */}
                  </li>
                </Animated>
              ))}
          </ul>
        </div>
      </div>
      {showEditAppModal && (
        <EditAppModal
          setShowModal={setShowEditAppModal}
          appId={editAppId}
          existingTags={editTags}
          suggestions={tagSuggestions}
          queryClient={queryClient}
        />
      )}
    </>
  );
}
