import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { getGrade } from "../../utils";
import ActivityFeed from "./ActivityFeed";
import NewsFeed from "./NewsFeed";
import RiskStats from "./RiskStats";
import AppCategories from "./AppCategories";
import LatestResults from "./LatestResults";
import AppSplit from "./AppSplit";
import RiskScoreCircle from "./RiskScoreCircle";
import AverageRiskTime from "./AverageRiskTime";
import Dora from "./Dora";

export default function Dashboard() {
  let navigate = useNavigate();

  const appStats = useQuery(["appStats"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/dashboard/appStats`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  return (
    <>
      <h2 className="text-xl font-medium mb-5">My App Dashboard</h2>
      <div className="flex md:flex-row md:space-x-4 flex-col">
        {/* First Column */}
        <div className="md:w-1/3 w-full">
          <div className="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-6 space-y-4 mb-4">
            <h2 className="text-xl font-medium mb-2">My App Platform Split</h2>
            {appStats && appStats.data && (
              <AppSplit appStats={appStats.data?.data} />
            )}
          </div>
          <div className="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-6 space-y-4 mb-4">
            <h2 className="text-xl font-medium mb-2">
              My Risk Score Breakdown
            </h2>
            <div className="flex justify-center py-4">
              <div className="relative flex items-center justify-center flex-grow cursor-pointer" onClick={() => navigate('/apps?rag=R')}>
                <RiskScoreCircle
                  outerPercentage={25}
                  text={
                    appStats &&
                    appStats.data &&
                    appStats.data.data.riskScoreCount.high
                  }
                />
              </div>
              <div className="relative flex items-center justify-center flex-grow cursor-pointer" onClick={() => navigate('/apps?rag=A')}>
                <RiskScoreCircle
                  outerPercentage={60}
                  text={
                    appStats &&
                    appStats.data &&
                    appStats.data.data.riskScoreCount.medium
                  }
                />
              </div>
              <div className="relative flex items-center justify-center flex-grow cursor-pointer" onClick={() => navigate('/apps?rag=G')}>
                <RiskScoreCircle
                  outerPercentage={80}
                  text={
                    appStats &&
                    appStats.data &&
                    appStats.data.data.riskScoreCount.low
                  }
                />
              </div>
            </div>
          </div>
          <div className="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-6 space-y-4 mb-4">
            {/* <h2 className="text-xl font-medium mb-2">Activity Feed</h2>
            <ActivityFeed /> */}
            <h2 className="text-xl font-medium">OWASP Mobile Top 10</h2>
            <span className="text-xs text-gray-400">
              Breakdown of overall risk by category
            </span>
            {appStats && appStats.data && (
              <RiskStats appStats={appStats.data?.data} />
            )}
          </div>
        </div>

        {/* Second Column */}
        <div className="md:w-2/3 w-full">
          {/* First Row */}
          <div className="flex md:flex-row md:space-x-4 flex-col">
            <div className="md:w-1/2">
              <div className="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-6 space-y-4 mb-4">
                <h2 className="text-xl font-medium mb-2">
                  Average Risk over Time
                </h2>
                {appStats && appStats.data && (
                  <AverageRiskTime appStats={appStats.data?.data} />
                )}
              </div>
            </div>
            <div className="md:w-1/2">
              <div className="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-6 space-y-4 mb-4">
                <h2 className="text-xl font-medium mb-2">Latest Results</h2>
                {appStats && appStats.data && (
                  <LatestResults appStats={appStats.data?.data} />
                )}
              </div>
            </div>
          </div>
          {/* Second Row */}
          <div className="flex md:flex-row md:space-x-4 flex-col">
            <NewsFeed />
          </div>
        </div>
      </div>
    </>
  );
}
