import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import "./tags.css";

export default function Tags() {
  const queryClient = useQueryClient();

  const mytags = useQuery(["mytags"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/mytags`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  return (
    <>
      <div className="flex justify-between">
        <h2 className="text-xl font-medium">Tags</h2>
      </div>
      <div className=" mt-5">
        <p className="text-sm">
          Here's a list of all the tags that you are currently using, and the
          counts of apps and users
        </p>
      </div>

      <div class=" py-4 flex justify-center mt-5">
        <table class="w-full text-md bg-white shadow-md rounded mb-4">
          <tbody>
            <tr class="border-b">
              <th class="text-left p-3 px-5  w-1/4">Tag</th>
              <th class="text-left p-3 px-5  w-1/2">Apps Count</th>
              <th class="text-left p-3 px-5  w-1/2">Users Count</th>
              <th></th>
            </tr>

            {mytags.status == "success" &&
              mytags.data &&
              Object.keys(mytags.data.data).map((tag) => {
                return (
                  <tr class="border-b hover:bg-orange-100 bg-gray-100 text-sm">
                    <td class="p-3 px-5 w-20">{tag}</td>
                    <td class="p-3 px-5">{mytags.data.data[tag].appCount}</td>
                    <td class="p-3 px-5">{mytags.data.data[tag].userCount}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
