import React, { useState } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import axios from "axios";

export default function ManualUpload({ setShowLoading }) {
  const navigate = useNavigate();

  const [appName, setAppName] = useState("");
  const [appType, setAppType] = useState("");
  const [appStoreUrl, setAppStoreUrl] = useState("");
  const [appDescription, setAppDescription] = useState("");
  const [appDeveloper, setAppDeveloper] = useState("");
  const [appIconUrl, setAppIconUrl] = useState("");
  const [appRating, setAppRating] = useState("");
  const [appDownloads, setAppDownloads] = useState("");
  const [file, setFile] = useState();

  const saveApp = () => {
    setShowLoading(true);
    const appDetails = {
      appName,
      appType,
      appStoreUrl,
      appDescription,
      appDeveloper,
      appIconUrl,
      appRating,
      appDownloads,
    };
    console.log(appName);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("appName", appName);
    formData.append("appType", appType);
    formData.append("appStoreUrl", appStoreUrl);
    formData.append("appDescription", appDescription);
    formData.append("appDeveloper", appDeveloper);
    formData.append("appIconUrl", appIconUrl);
    formData.append("appRating", appRating);
    formData.append("appDownloads", appDownloads);

    let token = localStorage.getItem("tokenmob");
    axios
      .post(`${process.env.REACT_APP_API_ROOT}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res);
        setShowLoading(false);
        clearForm();
        navigate("/apps");
      });

    // navigate('/apps');
  };

  const clearForm = () => {
    setAppName("");
    setAppType("");
    setAppStoreUrl("");
    setAppDescription("");
    setAppDeveloper("");
    setAppIconUrl("");
    setAppRating("");
    setAppDownloads("");
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <h2 className="text-xl font-medium">Manually Upload an Application</h2>
      </div>
      <div className="bg-white py-8 px-4 mt-6 shadow sm:rounded-lg sm:px-10 space-y-8 divide-y divide-gray-200">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            App Name *
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              name="app-name"
              id="app-name"
              className="max-w-lg block w-full shadow-sm focus:ring-mobstr-purple focus:border-mobstr-purple sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              value={appName}
              onChange={(e) => setAppName(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="country"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            App Type *
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="app-type"
              name="app-type"
              className="max-w-lg block focus:ring-mobstr-purple focus:border-mobstr-purple w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              value={appType}
              onChange={(e) => setAppType(e.target.value)}
            >
              <option value="apk">Android APK</option>
              <option value="crx">Chrome CRX</option>
            </select>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="cover-photo"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Application Binary *
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-mobstr-purple focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-mobstr-purple"
                  >
                    <span>{!file ? "Upload a file" : file.name}</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={handleFileChange}
                      required
                    />
                  </label>
                  {/* <p className="pl-1">or drag and drop</p> */}
                </div>
                <p className="text-xs text-gray-500">apk, crx</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-8 px-4 mt-6 shadow sm:rounded-lg sm:px-10 space-y-8 divide-y divide-gray-200">
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              App Store URL
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="max-w-lg flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                  https://
                </span>
                <input
                  type="text"
                  name="store-url"
                  id="store-url"
                  value={appStoreUrl}
                  onChange={(e) => setAppStoreUrl(e.target.value)}
                  className="flex-1 block w-full focus:ring-mobstr-purple focus:border-mobstr-purple min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                />
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="about"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Description
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                id="description"
                name="description"
                rows={3}
                className="max-w-lg shadow-sm block w-full focus:ring-mobstr-purple focus:border-mobstr-purple sm:text-sm border border-gray-300 rounded-md"
                defaultValue={""}
                value={appDescription}
                onChange={(e) => setAppDescription(e.target.value)}
              />
              <p className="mt-2 text-sm text-gray-500">
                Write a brief description of the app
              </p>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Developer/Publisher
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                name="app-developer"
                id="app-developer"
                value={appDeveloper}
                onChange={(e) => setAppDeveloper(e.target.value)}
                autoComplete="given-name"
                className="max-w-lg block w-full shadow-sm focus:ring-mobstr-purple focus:border-mobstr-purple sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Icon URL
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                name="icon-url"
                id="icon-url"
                value={appIconUrl}
                onChange={(e) => setAppIconUrl(e.target.value)}
                className="max-w-lg block w-full shadow-sm focus:ring-mobstr-purple focus:border-mobstr-purple sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Rating
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                name="app-rating"
                id="app-rating"
                value={appRating}
                onChange={(e) => setAppRating(e.target.value)}
                className="max-w-lg block w-full shadow-sm focus:ring-mobstr-purple focus:border-mobstr-purple sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Downloads
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                name="app-downloads"
                id="app-downloads"
                value={appDownloads}
                onChange={(e) => setAppDownloads(e.target.value)}
                className="max-w-lg block w-full shadow-sm focus:ring-mobstr-purple focus:border-mobstr-purple sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5 ">
        <div className="flex justify-end mb-5">
          <button
            type="button"
            className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
            onClick={saveApp}
          >
            Upload
          </button>
        </div>
      </div>
    </>
  );
}
