import React from "react";
import axios from "axios";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { formatDistance } from "date-fns";
import {
  faApple,
  faAndroid,
  faChrome,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Inbox() {
  const nist = useQuery(["nist"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/nist`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  return (
    <>
      <div className="flex justify-between">
        <h2 className="text-xl font-medium">Latest Mobile Vulnerabilities</h2>
      </div>
      <div className="flex mt-5">
        <p className="text-sm">
          Below is a list of the latest mobile vulnerabilities from NIST. Whilst
          we do make an automated comparison against sdks and libraries, it is
          always worth checking the latest vulnerabilities and ensuring there's
          nothing related to your apps.
        </p>
      </div>
      <ul role="list" className="space-y-3 mt-6">
        {nist.data?.data
          .sort(function (a, b) {
            return b.publishedDate - a.publishedDate;
          })
          .map((item) => {
            return (
              <li
                key={item.id}
                className="bg-white shadow overflow-hidden px-4 py-4 sm:px-6 sm:rounded-md relative"
              >
                <div class="flex  items-center">
                  <span className=" h-7 w-7 flex items-center justify-center align-middle text-center bg-mobstr-purple text-white rounded-full">
                    {item.platform == "iphone_os" && (
                      <FontAwesomeIcon icon={faApple} />
                    )}
                    {item.platform == "android" && (
                      <FontAwesomeIcon icon={faAndroid} />
                    )}
                    {item.platform == "chrome" && (
                      <FontAwesomeIcon icon={faChrome} />
                    )}
                  </span>

                  <h3 class="text-md font-semibold ml-3">
                    <a
                      href={`https://nvd.nist.gov/vuln/detail/${item.id}`}
                      target={"_blank"}
                    >
                      {item.id}
                    </a>
                  </h3>
                  <p class="text-xs text-gray-400 ml-auto">
                    {formatDistance(new Date(item.publishedDate), new Date(), {
                      addSuffix: true,
                    })}
                  </p>
                </div>
                <div class="text-sm italic text-gray-400 pt-2">
                  {item.description}
                </div>
              </li>
            );
          })}
      </ul>
    </>
  );
}
