import React, { useState, useEffect } from "react";
import AddUserModal from "./AddUserModal";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import ConfirmDialog from "../Dialog/ConfirmDialog";
import EditUserModal from "./EditUserModal";
import ReactTags from "react-tag-autocomplete";
import "./tags.css";
import { CheckIcon } from "@heroicons/react/solid";

export default function Users() {
  const queryClient = useQueryClient();

  const myusers = useQuery(["myusers"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/myusers`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  // Mutations
  const deleteMutation = useMutation(
    () => {
      let token = localStorage.getItem("tokenmob");
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/user/delete`,
        {
          id: deleteUserId,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["users"]);
        setDeleteUserId(null);
      },
    }
  );

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [tagSuggestions, setTagSuggestions] = useState([]);

  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [editUserId, setEditUserId] = useState();
  const [editTags, setEditTags] = useState([]);

  const showAddUser = () => {
    setShowAddUserModal(true);
  };
  const confirmDeleteUser = (id) => {
    setDeleteUserId(id);
    setConfirmOpen(true);
  };
  const deleteUser = () => {
    deleteMutation.mutate();
  };

  useEffect(() => {
    if (myusers.status == "success" && myusers.data.data) {
      let tagsList = [];
      myusers.data.data.forEach((user) => {
        if (user.tags) {
          user.tags.forEach((tag) => {
            if (
              tagsList.findIndex((suggestion) => suggestion.tag === tag.tag) ===
              -1
            ) {
              tagsList.push({ ...tag, name: tag.tag });
            }
          });
        }
      });
      setTagSuggestions(tagsList);
      console.log(tagsList);
    }
  }, [myusers.data]);

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between">
        <h2 className="text-xl font-medium">Users</h2>
        <button
          onClick={showAddUser}
          className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase mt-4 sm:mt-0"
        >
          Add User
        </button>
      </div>
      <div className="mt-5 text-sm">
        <p className="mb-5">
          Here you can invite users to your Mobstr instance, all apps that users
          add will be visible in your dashboard. Once invited, a user will
          receive an email to setup their account. To revoke a user's access,
          simply delete their invite or disable their active account. Their apps
          will remain in your account.
        </p>
        <p>
          You can add tags to a user to categorise or organise them. All other
          users with the exact same tag will automatically share added apps
          between them.
        </p>
      </div>

      <div className="py-4 flex justify-center mt-5">
        <div className="w-full overflow-x-auto">
          <table className="w-full text-md bg-white shadow-md rounded mb-4 max-w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left p-3 px-5 w-1/4">Email</th>
                <th className="text-left p-3 px-5 w-1/3">Tags</th>
                <th className="text-left p-3 px-5 w-1/6">Approver</th>
                <th className="text-left p-3 px-5 w-1/6">Status</th>
                <th className="text-left p-3 px-5 w-1/6">Actions</th>
              </tr>
            </thead>
            <tbody>
              {myusers.status == "success" &&
                myusers.data &&
                myusers.data.data.map((user) => {
                  return (
                    <tr
                      className="border-b hover:bg-orange-100 bg-gray-100 text-sm"
                      key={user.id}
                    >
                      <td className="p-3 px-5 break-words">{user.email}</td>
                      <td className="p-3 px-5">
                        <div className="flex flex-wrap">
                          {user &&
                            user.tags.length > 0 &&
                            user.tags.map((tag, index) => (
                              <span
                                key={index}
                                className={
                                  "text-[9px] inline-flex items-center font-bold leading-sm uppercase px-3 py-1 mr-1 mb-1 bg-mobstr-purple text-white rounded-full"
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-4 h-4 mr-1"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 6h.008v.008H6V6z"
                                  />
                                </svg>
                                {tag.tag}
                              </span>
                            ))}
                          <div
                            className="text-xs text-gray-300 flex items-center justify-center cursor-pointer mt-1"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditUserId(user.id);
                              setEditTags(
                                user.tags.length > 0 ? user.tags : []
                              );
                              setShowEditUserModal(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                              />
                            </svg>
                            <p>Edit Tags</p>
                          </div>
                        </div>
                      </td>
                      <td className="p-3 px-5">
                        {user.approver ? (
                          <CheckIcon
                            className="-ml-1 mr-2 h-5 w-5 inline text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="p-3 px-5">
                        {user.isVerified ? (
                          <p
                            className={
                              "inline-block border border-green-600 rounded py-1 px-3 text-white bg-green-600"
                            }
                          >
                            Active
                          </p>
                        ) : (
                          <p
                            className={
                              "inline-block border border-yellow-300 rounded py-1 px-3 text-white bg-yellow-500"
                            }
                          >
                            Invited
                          </p>
                        )}
                      </td>
                      <td className="p-3 px-5">
                        <button
                          type="button"
                          className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-3 rounded focus:outline-none focus:shadow-outline"
                          onClick={() => confirmDeleteUser(user.id)}
                        >
                          {user.isVerified ? "Disable" : "Delete"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      <AddUserModal
        showModal={showAddUserModal}
        setShowModal={setShowAddUserModal}
      />
      {showEditUserModal && (
        <EditUserModal
          setShowModal={setShowEditUserModal}
          userId={editUserId}
          existingTags={editTags}
          suggestions={tagSuggestions}
        />
      )}
      <ConfirmDialog
        title="Delete/Disable User?"
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={deleteUser}
      >
        Are you sure you want to delete/disable this invite/user?
      </ConfirmDialog>
    </>
  );
}
