import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";

function NewsFeed() {
  const newsItems = useQuery(["news"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/dashboard/news`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  return (
    <>
      {newsItems.data &&
        newsItems.data.data.map((item) => (
          <div className="md:w-1/3">
            <div className=" bg-white shadow border border-gray-200 sm:rounded-lg  max-w-sm mb-4">
              <a href={item.url} target="_blank" rel="noreferrer">
                <img
                  className="rounded-t-lg max-h-32 min-w-full"
                  src={item.imageUrl}
                  alt=""
                />
              </a>

              <div className="p-4">
                <a href={item.url} target="_blank" rel="noreferrer">
                  <h2 className="text-xl font-medium text-gray-900">
                    {item.title}
                  </h2>
                </a>
                <span className="text-xs text-gray-400">
                  {new Date(item.publishedAt).toLocaleString([], {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
                <p className="mt-2 mb-3 font-normal text-gray-700 dark:text-gray-400 max-h-48 overflow-hidden">
                  {item.description}
                </p>
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

export default NewsFeed;
