import React, { useState } from "react";
import AppChooser from "../AppChooser/AppChooser";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";

export default function AddAppModal({ showModal, setShowModal }) {
  const [selectedApp, setSelectedApp] = useState({});

  const [storeUrl, setStoreUrl] = useState("");
  const [appId, setAppId] = useState("");
  const [store, setStore] = useState("");

  const [appName, setAppName] = useState("");
  const [appPublisher, setAppPublisher] = useState("");
  const [appBundleId, setAppBundleId] = useState("");
  const [appIcon, setAppIcon] = useState("");
  const [appSuccess, setAppSuccess] = useState(false);

  const queryClient = useQueryClient();

  // Mutations
  const mutation = useMutation(
    () => {
      let token = localStorage.getItem("tokenmob");
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/addapp`,
        {
          appId: appId,
          name: appName,
          icon: appIcon,
          platform:
            store === "android" ? store : store === "chrome" ? "chrome" : "ios",
          publisherId: "",
          publisherName: appPublisher,
          bundleId: appBundleId,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        },
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["myapps"]);
        closeMe();
      },
    },
  );

  const appInfoMutation = useMutation(
    (postObj) => {
      console.log();
      setAppSuccess(false);
      let token = localStorage.getItem("tokenmob");
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/apps/details`,
        postObj,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        },
      );
    },
    {
      onSuccess: (data) => {
        console.log(data);
        if (data.data.name) {
          setAppIcon(data.data.icon);
          setAppName(data.data.name);
          setAppPublisher(data.data.developer);
          setAppBundleId(data.data.bundleId);
          setAppSuccess(true);
        } else {
          setAppName("Application Not Found - Check the URL again");
        }
      },
    },
  );

  const clickSearchApp = () => {
    parseAppId();
  };

  const parseAppId = () => {
    if (storeUrl.indexOf("apple.com") > -1) {
      let appId = storeUrl.split("/id")[1];
      console.log(appId);
      setAppId(appId);
      setStore("itunes");
      appInfoMutation.mutate({ appId, store: "itunes" });
    } else if (storeUrl.indexOf("https://play.google.com") > -1) {
      let appId = storeUrl.split("?id=")[1];
      appId = appId.split("&")[0];
      console.log(appId);
      setAppId(appId);
      setStore("android");
      appInfoMutation.mutate({ appId, store: "android" });
    } else if (storeUrl.indexOf("https://chromewebstore.google.com/") > -1) {
      let appId = storeUrl.split("/")[storeUrl.split("/").length - 1];
      console.log(appId);
      setAppId(appId);
      setStore("chrome");
      appInfoMutation.mutate({ appId, store: "chrome" });
    } else {
      //TODO : Error
    }
  };

  const clickAddApp = () => {
    // if(store == 'itunes') {
    //   alert('Please contact us to enable IOS Applications for your account');
    //   return;
    // }
    mutation.mutate();
    //closeMe();
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = "/mobstr_logo.png";
  };

  const closeMe = () => {
    setShowModal(false);
    setAppName(null);
    setAppId(null);
    setAppPublisher(null);
    setStore(null);
    setAppSuccess(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Add an App</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={closeMe}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className=" text-slate-500 text-sm leading-relaxed">
                    Paste the app store URL of the app you want to add below.
                    You can search for apps in either the{" "}
                    <a
                      className={"underline"}
                      href="https://play.google.com/store/apps"
                      target="_blank"
                    >
                      Play Store (Android)
                    </a>
                    {", "}
                    <a
                      className={"underline"}
                      href="https://www.apple.com/uk/search/"
                      target="_blank"
                    >
                      App Store (iOS)
                    </a>{" "}
                    or{" "}
                    <a
                      className={"underline"}
                      href="https://chrome.google.com/webstore/category/extensions"
                      target="_blank"
                    >
                      Chrome Web Store
                    </a>{" "}
                    open the apps page, copy the URL from the address bar, paste
                    it here and click search.
                  </p>

                  <div class="relative w-full mt-3">
                    <input
                      type="search"
                      id="app_search"
                      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-mobstr-purple focus:border-mobstr-purple"
                      placeholder="e.g. https://play.google.com/... "
                      required
                      onChange={(e) => setStoreUrl(e.target.value)}
                    />
                    <button
                      type="button"
                      class="absolute top-0 right-0 p-2.5 h-full text-sm font-medium text-white bg-mobstr-purple rounded-r-lg border hover:bg-[#3d627a]"
                      onClick={clickSearchApp}
                    >
                      <svg
                        aria-hidden="true"
                        class="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </button>
                  </div>

                  {/* <AppChooser setSelectedApp={setSelectedApp} selectedApp={selectedApp}/> */}
                </div>

                {appName && (
                  <div className="relative px-6 pb-4 flex-auto">
                    <div className="flex items-center">
                      <div className="min-w-0 flex-1 flex items-center cursor-pointer">
                        <div className="flex-shrink-0">
                          <img
                            className="h-12 w-12 rounded-lg border "
                            src={appIcon}
                            onError={addDefaultSrc}
                          />
                        </div>
                        <div className="min-w-0 flex-1 px-3 md:grid md:grid-cols-2 md:gap-2">
                          <div>
                            <p className="text-sm font-medium">{appName}</p>
                            <p className="flex items-stretch text-xs text-gray-500">
                              {/* {application.category_ids[0].replace("_", " ")} */}
                              <span className="truncate">{appPublisher}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 uppercase background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={closeMe}
                  >
                    Close
                  </button>
                  <button
                    disabled={!appSuccess}
                    className="text-white bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                    type="button"
                    onClick={clickAddApp}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
