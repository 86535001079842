import { useState, useEffect } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import ConfirmDialog from "../Dialog/ConfirmDialog";

export default function Settings() {
  const queryClient = useQueryClient();

  const [tenantId, setTenantId] = useState("");
  const [confirmOpen, setConfirmOpen] = useState("");
  const [showInstructions, setShowInstructions] = useState(false);

  const me = useQuery(["user"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/me`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  useEffect(() => {
    if (me && me.data && me.data.data?.tenantId) {
      setTenantId(me.data.data.tenantId);
    }
  }, [me.data]);

  const doSaveTenant = () => {
    saveTenantMutation.mutate({ tid: tenantId });
  };

  const doOpenPermissions = () => {
    window.open(
      `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=85fff194-a040-45ac-a47e-dde7002a714a`,
      "_blank"
    );
  };

  const saveTenantMutation = useMutation(
    ({ tid }) => {
      let token = localStorage.getItem("tokenmob");
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/savetenant`,
        {
          tenantId: tid,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user"]);
        setConfirmOpen(true);
      },
    }
  );

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <h2 className="text-xl font-medium">
            MDM Integration (Microsoft Intune)
          </h2>
          <br />
          <p>
            To syncronise apps with Microsoft Intune, Enter your Microsoft
            TenantId below, and click the button below to authorise permissions.
            Your tenant ID will be available in your tenant administration
            screen within InTune. Once authorised, your apps will be
            syncronised, and scanned overnight.
          </p>

          <button
            onClick={() => setShowInstructions(!showInstructions)}
            className="underline text-mobstr-purple text-sm my-4 text-left"
          >
            {showInstructions
              ? "Hide how to find your Tenant Id?"
              : "Show how to find your Tenant Id?"}
          </button>

          {showInstructions && (
            <div className="instructions mb-4">
              <img
                src="/tenantid.png"
                alt="How to find your Tenant Id"
                className="w-full md:w-1/2"
              />
            </div>
          )}

          <div className="w-full">
          <input
            type="text"
            value={tenantId}
            onChange={(e) => {
              setTenantId(e.target.value);
            }}
            id="tenant_id"
            className="w-full md:w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mobstr-purple focus:border-mobstr-purple block p-2.5"
            placeholder="Enter Tenant ID "
            required
          />
            <button
              type="submit"
              onClick={doSaveTenant}
              className="text-white w-24 mt-4 bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm sm:w-auto px-5 py-2.5 text-center uppercase"
            >
              Save
            </button>
            <br />

            {me && me.data && me.data.data?.tenantId && (
              <>
                <p className={"mt-4"}>
                  If you have changed your TenantID you will need to
                  re-authorise Mobstr with the below button.
                </p>
                <button
                  type="submit"
                  onClick={doOpenPermissions}
                  className="text-white w-24 mt-4 bg-[#2c4251] hover:bg-[#3d627a] font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                >
                  Authorise Permission
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <ConfirmDialog
        title="Tenant ID Saved"
        children="Please use the 'Authorise Permission' button to grant permissions to Mobstr to connect to your Intune instance."
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => setConfirmOpen(false)}
        hideNo={true}
      ></ConfirmDialog>
    </>
  );
}
