"use client";
import React, { useState } from 'react';
import './UsersReport.css';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { CheckIcon } from "@heroicons/react/solid";

const UsersReports = () => {

	const [showUsersReport, setShowUsersReport] = useState(false);

	const adminUsers = useQuery(["adminUsers"], () => {
		let token = localStorage.getItem("tokenmob");
		return axios.get(`${process.env.REACT_APP_API_ROOT}/admin/users`, {
		  headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		  },
		});
	  });


	const clickShowUsersReport = () => {
setShowUsersReport(true);
	};

	return (
		<>
		<p className='text-red-70 mt-3 mb-3 font-bold'>
			Caution! This is the Admin Section. Check surrounding security, and screen security, then click below to show users report. 
			
		</p>
		<p>
		<button
                  onClick={clickShowUsersReport}
                  type="button"
                  className="text-white bg-red-600 font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                >
                 
                  Show Users Report
                </button>
		</p>
		{showUsersReport && <div className='mt-10'>
 			


			<div className="py-4 flex justify-center mt-5">
        <div className="w-full overflow-x-auto">
          <table className="w-full text-md bg-white shadow-md rounded mb-4 max-w-full">
            <thead>
              <tr className="border-b">
			
				<th className="text-left p-3 px-5 w-1/4">ID</th>
                <th className="text-left p-3 px-5 w-1/4">Email</th>
				<th className="text-left p-3 px-5 w-1/4">Parent</th>
                <th className="text-left p-3 px-5 w-1/3">Invited</th>
				<th className="text-left p-3 px-5 w-1/3">Accept</th>
				<th className="text-left p-3 px-5 w-1/3">Active</th>
                <th className="text-left p-3 px-5 w-1/6">Apps</th>
				<th className="text-left p-3 px-5 w-1/6">Limit</th>
                <th className="text-left p-3 px-5 w-1/6">Expiry</th>
                <th className="text-left p-3 px-5 w-1/6">Admin</th>
				<th className="text-left p-3 px-5 w-1/6">MDM</th>
				<th className="text-left p-3 px-5 w-1/6">Notes</th>
              </tr>
            </thead>
            <tbody>
              {adminUsers.status === "success" &&
                adminUsers.data &&
                adminUsers.data.data.sort((a, b) => a.id < b.id ? 1 : -1).map((user) => {
                  return (
                    <tr
                      className="border-b hover:bg-orange-100 bg-gray-100 text-sm"
                      key={user.id}

                    >

                      <td className="p-3 px-5 break-words">{user.id}</td>
					  <td className="p-3 px-5 break-words">{user.email}</td>
					  <td className="p-3 px-5 break-words">{user.parentId}</td>
					  <td className="p-3 px-5 break-words">{new Date(user.createdAt).toLocaleDateString()}</td>
					  <td className="p-3 px-5">
                        {user.isVerified ? (
                          <CheckIcon
                            className="-ml-1 mr-2 h-5 w-5 inline text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          ""
                        )}
                      </td>
					  <td className="p-3 px-5 break-words">{user.lastActive && new Date(user.lastActive).toLocaleDateString() + ' ' + new Date(user.lastActive).toLocaleTimeString()}</td>
					  <td className="p-3 px-5 break-words">{user.apps.length}</td>
					  <td className="p-3 px-5 break-words">{user.appLimit}</td>
					  <td className="p-3 px-5 break-words">{user.trialExpiry && new Date(user.trialExpiry).toLocaleDateString()}</td>
                      <td className="p-3 px-5">
                        {user.isParentAdmin ? (
                          <CheckIcon
                            className="-ml-1 mr-2 h-5 w-5 inline text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          ""
                        )}
                      </td>
					  {/* <td className="p-3 px-5">
                        {user.approver ? (
                          <CheckIcon
                            className="-ml-1 mr-2 h-5 w-5 inline text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          ""
                        )}
                      </td> */}
					  <td className="p-3 px-5">
                        {user.hasMdm ? (
                          <CheckIcon
                            className="-ml-1 mr-2 h-5 w-5 inline text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          ""
                        )}
                      </td>

					  <td className="p-3 px-5 break-words">{user.notes}</td>
                      
                      
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

 		</div>
}
		</>
		
		
	);
};


export default UsersReports;