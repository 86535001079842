import { Tooltip } from "flowbite-react";

function RiskStats({ appStats }) {
  const getColour = (percentage) => {
    if (percentage < 20) {
      return "bg-green-600";
    } else if (percentage >= 20 && percentage < 60) {
      return "bg-yellow-400";
    } else if (percentage >= 60 && percentage < 101) {
      return "bg-red-600";
    } else {
      return "";
    }
  };
  return (
    <div>
      <Tooltip
        content="This occurs when apps do not properly handle or protect user credentials like passwords, leading to unauthorized access or data breaches."
        placement="top"
        className="bg-mobstr-purple w-80"
      >
        <p className="text-xs pb-1">M1: Improper Credential Usage</p>
      </Tooltip>

      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <div
          className={`${getColour(
            appStats.owaspCount.find((a) => a.category === "M1")?.percentage
          )} h-2.5 rounded-full`}
          style={{
            width: `${
              Math.round(
                appStats.owaspCount.find((a) => a.category === "M1")?.percentage
              ) ?? 0
            }%`,
          }}
        ></div>
      </div>

      <Tooltip
        content="Vulnerabilities can arise from third-party components or services that are integrated into the mobile app, potentially introducing security weaknesses."
        placement="top"
        className="bg-mobstr-purple w-80"
      >
      <p className="text-xs pb-1">M2: Inadequate Supply Chain Security</p>
      </Tooltip>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <div
          className={`${getColour(
            appStats.owaspCount.find((a) => a.category === "M2")?.percentage
          )} h-2.5 rounded-full`}
          style={{
            width: `${
              Math.round(
                appStats.owaspCount.find((a) => a.category === "M2")?.percentage
              ) ?? 0
            }%`,
          }}
        ></div>
      </div>

      <Tooltip
        content="This refers to flaws in how users are authenticated or granted permissions, which can lead to unauthorized access or privilege escalation."
        placement="top"
        className="bg-mobstr-purple w-80"
      >
      <p className="text-xs pb-1">M3: Insecure Authentication/Authorization</p>
      </Tooltip>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <div
          className={`${getColour(
            appStats.owaspCount.find((a) => a.category === "M3")?.percentage
          )} h-2.5 rounded-full`}
          style={{
            width: `${
              Math.round(
                appStats.owaspCount.find((a) => a.category === "M3")?.percentage
              ) ?? 0
            }%`,
          }}
        ></div>
      </div>

      <Tooltip
        content="Lack of proper validation of data input and output can lead to vulnerabilities like SQL injection or cross-site scripting (XSS), compromising the app."
        placement="top"
        className="bg-mobstr-purple w-80"
      >
      <p className="text-xs pb-1">M4: Insufficient Input/Output Validation</p>
      </Tooltip>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <div
          className={`${getColour(
            appStats.owaspCount.find((a) => a.category === "M4")?.percentage
          )} h-2.5 rounded-full`}
          style={{
            width: `${
              Math.round(
                appStats.owaspCount.find((a) => a.category === "M4")?.percentage
              ) ?? 0
            }%`,
          }}
        ></div>
      </div>

      <Tooltip
        content="When data transmitted between the mobile app and server is not properly encrypted or secured, it can be intercepted or tampered with by attackers."
        placement="top"
        className="bg-mobstr-purple w-80"
      >
      <p className="text-xs pb-1">M5: Insecure Communication</p>
      </Tooltip>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <div
          className={`${getColour(
            appStats.owaspCount.find((a) => a.category === "M5")?.percentage
          )} h-2.5 rounded-full`}
          style={{
            width: `${
              Math.round(
                appStats.owaspCount.find((a) => a.category === "M5")?.percentage
              ) ?? 0
            }%`,
          }}
        ></div>
      </div>

      <Tooltip
        content="Failure to properly protect users' private information can result in unauthorized access to sensitive data, violating privacy regulations and trust."
        placement="top"
        className="bg-mobstr-purple w-80"
      >
      <p className="text-xs pb-1">M6: Inadequate Privacy Controls</p>
      </Tooltip>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <div
          className={`${getColour(
            appStats.owaspCount.find((a) => a.category === "M6")?.percentage
          )} h-2.5 rounded-full`}
          style={{
            width: `${
              Math.round(
                appStats.owaspCount.find((a) => a.category === "M6")?.percentage
              ) ?? 0
            }%`,
          }}
        ></div>
      </div>

      <Tooltip
        content="Lack of protections on the app's binary code can make it easier for attackers to reverse-engineer, modify, or tamper with the app."
        placement="top"
        className="bg-mobstr-purple w-80"
      >
      <p className="text-xs pb-1">M7: Insufficient Binary Protections</p>
      </Tooltip>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <div
          className={`${getColour(
            appStats.owaspCount.find((a) => a.category === "M7")?.percentage
          )} h-2.5 rounded-full`}
          style={{
            width: `${
              Math.round(
                appStats.owaspCount.find((a) => a.category === "M7")?.percentage
              ) ?? 0
            }%`,
          }}
        ></div>
      </div>

      <Tooltip
        content="Insecure default settings, misconfigurations, or inadequate security controls can leave an app vulnerable to attacks."
        placement="top"
        className="bg-mobstr-purple w-80"
      >
      <p className="text-xs pb-1">M8: Security Misconfiguration</p>
      </Tooltip>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <div
          className={`${getColour(
            appStats.owaspCount.find((a) => a.category === "M8")?.percentage
          )} h-2.5 rounded-full`}
          style={{
            width: `${
              Math.round(
                appStats.owaspCount.find((a) => a.category === "M8")?.percentage
              ) ?? 0
            }%`,
          }}
        ></div>
      </div>

      <Tooltip
        content="Storing sensitive data (like passwords, tokens, or personal information) insecurely on the device can lead to data theft if the device is compromised."
        placement="top"
        className="bg-mobstr-purple w-80"
      >
      <p className="text-xs pb-1">M9: Insecure Data Storage</p>
      </Tooltip>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <div
          className={`${getColour(
            appStats.owaspCount.find((a) => a.category === "M9")?.percentage
          )} h-2.5 rounded-full`}
          style={{
            width: `${
              Math.round(
                appStats.owaspCount.find((a) => a.category === "M9")?.percentage
              ) ?? 0
            }%`,
          }}
        ></div>
      </div>

      <Tooltip
        content="Using weak or improperly implemented cryptography can result in data being easily decrypted by attackers, compromising the app's security."
        placement="top"
        className="bg-mobstr-purple w-80"
      >
      <p className="text-xs pb-1">M10: Insufficient Cryptography</p>
      </Tooltip>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <div
          className={`${getColour(
            appStats.owaspCount.find((a) => a.category === "M10")?.percentage
          )} h-2.5 rounded-full`}
          style={{
            width: `${
              Math.round(
                appStats.owaspCount.find((a) => a.category === "M10")
                  ?.percentage
              ) ?? 0
            }%`,
          }}
        ></div>
      </div>
    </div>
  );
}

export default RiskStats;
