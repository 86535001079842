import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function AverageRiskTime({appStats}) {

  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={appStats.scoreHistory}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month"  textAnchor="end" interval={0} display={'Month'} />
        {/* <Legend /> */}
        <Line
          type="monotone"
          dataKey="score"
          stroke="#8884d8"
          strokeWidth={2}
          dot={true}
          display={'App Risk Score'}
        />
        {/* <Line
          type="monotone"
          dataKey="Watching"
          stroke="#3399FF"
          strokeWidth={2}
          dot={false}
        />
        <Line
          type="monotone"
          dataKey="Overall"
          stroke="#82ca9d"
          strokeWidth={2}
          dot={false}
        /> */}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default AverageRiskTime;
